<template>
  <div class="header-box">
    <div class="octopus-width">
      <!-- 中间banner图 -->
      <div class="octopus-banner">
        <!-- 轮播图修改-->
        <div class="octopus-banner-left">
          <Carousel :scroll="scroll"></Carousel>
        </div>
        <div class="octopus-banner-right">
          <!-- 分为上下两部分 -->
          <!-- 未登录状态 -->
          <!-- 上面开始 -->
          <div class="octopus-banner-login">
            <div class="octopus-banner-login-font" v-show="!isLogin">
              <!-- hi -->
              <div class="octopus-banner-login-fonts">
                <Isvg icon-class="mall-login"/>
                <p>Hi~欢迎来到爪币商城</p>
              </div>
              <!-- 蓝色button按钮 -->
              <div class="octopus-banner-login-font-button">
                <button class="button-blue-80" @click="$router.push('login')">
                  登录
                </button>
              </div>
            </div>

            <div class="octopus-banner-login-zhuabi" v-show="isLogin">
              <div class="octopus-banner-login-zhuabi-center">
                <div class="octopus-banner-login-zhuabi-left">
                  <p>
                    我的爪币：<span> {{ info.coin }} </span>
                  </p>
                </div>
                <div class="octopus-banner-login-zhuabi-right">
                  <button class="button-blue-80" @click="toPage('recharge')">
                    充值
                  </button>
                </div>
              </div>
              <div class="octopus-banner-login-dingdan">
                <div class="octopus-banner-login-dingdan-mine" @click="toPage('myOrders')">
                  <p>
                    <Isvg icon-class="mall-order"/>
                  </p>
                  <p>我的订单</p>
                </div>
                <div class="octopus-banner-login-dingdan-mine" @click="toPage('coin')">
                  <p>
                    <Isvg icon-class="mall-coin"/>
                  </p>
                  <p>积分说明</p>
                </div>
                <div class="octopus-banner-login-dingdan-mine" @click="toPage('question')">
                  <p>
                    <Isvg icon-class="mall-question"/>
                  </p>

                  <p>常见问题</p>
                </div>
              </div>
            </div>
          </div>

          <!-- 上面结束 -->
          <!-- 下面开始 -->
          <div class="octopus-banner-proclamation">
            <div class="octopus-banner-proclamation-main">
              <div class="octopus-banner-proclamation-main-top">
                <!-- 先用span替代 -->
                <span>商城公告</span>
                <!-- icon暂时不写 -->
                <a><img src="@/icons/svg/shop/圆箭头-右12X12.svg"/></a>
              </div>
              <div class="octopus-banner-proclamation-main-bottom">
                <ol>
                  <li v-for="(item, key) in notice" :key="key">
                    {{ item.title }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <!-- 下面结束 -->
        </div>
      </div>
      <!-- 下面两张banner图 -->
      <div class="octopus-twoImages">
        <!-- 左边图 -->
        <div class="octopus-twoImages-left">
          <img src="@/assets/banner-left.png" alt=""/>
        </div>
        <!-- 右边图 -->
        <div class="octopus-twoImages-right">
          <img src="@/assets/banner-right.jpeg" alt=""/>
        </div>
      </div>
      <!-- 商品页面布局 -->
      <div class="octopus-commodity">
        <!-- 上半部分切换开始 -->
        <el-menu class="el-menu-demo" text-color="#575757" active-text-color="#FFFF" active-background-color="#00a2e6"
                 mode="horizontal">
          <el-menu-item
              :index="(k + 1).toString()"
              v-for="(cate, k) in category"
              :key="k"
              @click="changeCateData(cate.id, k)"
          >{{ cate.name }}
          </el-menu-item
          >
        </el-menu>
        <!-- 上半部分切换结束 -->
        <!-- 下半部分展示开始 -->
        <Cups v-loading="CupsLoading" :catId="catId" :target="'router'"></Cups>
        <div class="octopus-more-shop">
          <button @click="toloadList()">更多商品</button>
        </div>
        <!-- 下半部分展示结束 -->
      </div>
    </div>
  </div>
</template>

<script>
import Cups from "@/components/Cups/Cups";
import {goodsIndex, goodsList} from "@/api/goods";

export default {
  name: "home",
  components: {
    Cups,
  },
  computed: {
    isLogin() {
      return this.$store.getters["useUserStore/getToken"]
    }
  },
  data() {
    return {
      pageParams: {
        page: 1,
        page_size: 10,
      },
      loadLock: true,

      info: {},
      notice: [],
      recommend: [],
      category: [],
      catId: 0,
      // 实现banner图下文字的颜色切换
      hoverBlue: -1,
      CupsLoading: false,
      //轮播图
      scroll: [
        {
          id: "1",
          imgUrl: require("@/assets/scroll3.jpeg"),
        },
        {
          id: "2",
          imgUrl: require("@/assets/scroll2.jpeg"),
        },
        {
          id: "3",
          imgUrl: require("@/assets/scroll1.jpeg"),
        },
      ],
    };
  },
  created() {
    this.loadIndex();
    this.info = this.$store.getters["useUserStore/getUserInfo"]
  },
  methods: {
    async loadIndex() {
      const res = await goodsIndex();
      if (res.statusCode == 200) {
        //this.scroll = res.data.scroll;
        this.notice = res.data.notice;
        this.recommend = res.data.recommend;
        this.category = res.data.category;
      }
    },
    async loadList() {
      const res = await goodsList();
      if (res.statusCode == 200) {
        this.listData = res.data.list;
        this.pageParams.page = parseInt(res.data.pages.page);
        this.pageParams.page_size = parseInt(res.data.pages.page_size);
      }
    },
    changeCateData(cid, k) {
      this.catId = cid;
      this.CupsLoading = true;
      setTimeout(() => {
        this.CupsLoading = false;
      }, 1000);
      this.changeIndex(k);
    },
    toPage(goPage) {
      switch (goPage) {
        case "myOrders":
          this.$router.push("/home/coin/record");
          break;
        case "coin":
          this.$router.push("/home/coin/mine");
          break;
        case "question":
          this.$router.push("question");
          break;
        case "recharge":
          this.$router.push("/home/account/recharge");
          break;
      }
    },
    /*移入 改变hoverBlue的值方便文字颜色的修改 */
    changeIndex(k) {
      this.hoverBlue = k;
    },
    toloadList() {
      if (this.loadLock) {
        this.loadLock = false;
        this.loadList();
        setTimeout(() => {
          this.loadLock = true;
        }, 1000);
      } else {
        this.$notify({
          message: "正在努力加载不要着急.....",
          type: "warning",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.header-box {
  margin-top: 10px;
  background-color: #f8f8f8;
}

/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  background-color: #00a2e6;
  border-bottom: none;
}

/deep/ .el-menu.el-menu--horizontal {
  margin-bottom: 20px;
  border: none;
}

/deep/ .el-menu--horizontal > .el-menu-item:hover {
  color: #00a2e6 !important;
}

/deep/ .el-menu--horizontal > .el-menu-item.is-active:hover {
  color: #FFFF !important;
}

.octopus-width {
  width: 1200px;
  margin: 0 auto;
}

.octopus-banner {
  display: flex;
  width: 1200px;
  justify-content: space-between;
  margin-bottom: 20px;

  .octopus-banner-left {
    width: 860px;
    height: 400px;
  }

  .octopus-banner-right {
    width: 330px;
    height: 400px;

    .octopus-banner-login {
      width: 330px;
      height: 190px;
      background: #ffffff;
      margin-bottom: 13px;
      // 未登录
      .octopus-banner-login-font {
        display: flex;
        align-items: center;
        flex-direction: column;

        .octopus-banner-login-fonts {
          text-align: center;
          font-size: 14px;
          position: relative;
          top: 41px;

          img {
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-bottom: 10px;
          }
        }

        .octopus-banner-login-font-button {
          button {
            margin-top: 70px;
          }
        }
      }

      // 登录之后的
      .octopus-banner-login-zhuabi {
        width: 330px;
        height: 190px;
        display: flex;
        flex-direction: column;

        .octopus-banner-login-zhuabi-center {
          width: 290px;
          display: flex;
          height: 54px;
          align-items: center;
          margin: 0 auto;
          justify-content: space-between;
          border-bottom: 1px solid #b5b5b5;
        }

        .octopus-banner-login-zhuabi-left {
          margin: 20px 0 10px 0;

          p {
            font-size: 14px;
            color: #474747;

            span {
              font-size: 16px;
              color: #e4007f;
            }
          }
        }

        .octopus-banner-login-dingdan {
          display: flex;
          justify-content: space-around;
          margin-top: 40px;

          .octopus-banner-login-dingdan-mine {
            width: 58px;
            text-align: center;
            font-size: 14px;
            cursor: pointer;

            & > p:first-of-type {
              margin-bottom: 12px;
            }

            svg {
              width: 48px;
              height: 48px;
            }
          }
        }
      }
    }

    .octopus-banner-proclamation {
      width: 330px;
      height: 195px;
      background: #ffffff;
    }

    .octopus-banner-proclamation-main {
      width: 290px;
      margin: 0 auto;

      .octopus-banner-proclamation-main-top {
        font-size: 18px;
        /* padding: 20px 0 10px 0; */
        height: 54px;
        border-bottom: 1px solid #b5b5b6;

        span {
          position: relative;
          top: 20px;
          margin-right: 10px;
        }

        img {
          position: relative;
          top: 20px;
          width: 12px;
          height: 12px;
        }
      }

      .octopus-banner-proclamation-main-bottom {
        ol {
          height: 104px;
          margin-top: 20px;

          li {
            cursor: pointer;
            font-size: 14px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  /* 上下可以用同一个类目来写 */
}

.octopus-twoImages {
  width: 1200px;
  display: flex;
  justify-content: space-between;

  img {
    width: 595px;
    height: 236px;
  }
}

/* 下面商品展示区 */
.octopus-commodity {
  margin-top: 51px;

  .octopus-commodity-font {
    font-size: 18px;
    margin-bottom: 28px;

    ul {
      display: flex;

      li {
        margin-right: 41px;
      }

      & > li:last-of-type {
        margin-right: none;
      }

      & > .blue {
        cursor: pointer;
        color: #00a2e6;
      }
    }
  }

  .Cup-box {
    border: 1px solid red;
    width: 1200px;
    flex-wrap: wrap;
    justify-content: space-between;

    .bt {
      margin-bottom: 10px;
    }
  }
}

/* 更多按钮 */
.octopus-more-shop {
  width: 200px;
  margin: 0 auto;
  margin-top: 50px;

  button {
    width: 200px;
    height: 36px;
    background: #00a2e6;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
  }
}

/deep/ .el-menu-item.is-active {
  background-color: #00a2e6 !important;
}
</style>
