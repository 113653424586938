<template>
  <div class="Cup-boxs">
    <div class="Cup-box">
      <el-empty v-if="recommendGoodsData.length == 0" style="margin: 0 auto;" description="暂无数据"></el-empty>

      <div
        class="cup"
        v-for="(icup, i) in recommendGoodsData"
        :key="i"
      >
        <!-- cup图片 -->
        <div class="cup-img">
          <img v-lazy="icup.thumb" alt="" @click="replacePage(icup.id)"/>
        </div>
        <!-- cup的介绍 -->
        <div class="cup-font">
          <div class="cup-introduce">
            <p>{{ icup.name }}</p>
          </div>
          <!-- cup的积分 -->
          <div class="cup-money">
            <div class="cup-integral">
              <strong class="cup-number">{{ icup.price }}</strong
              ><strong class="cup-fen">积分</strong>
            </div>
            <!-- 实际金钱 -->
            <div class="cup-price">
              <span>￥{{ icup.mkt_price }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goodsList } from "@/api/goods";
export default {
  name: "Cup",
  props: {
    catId: {
      type: Number,
      default: 0,
    },
    goodsId: {
      type: Number,
      default: 0,
    },
    target: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      recommendGoodsData: [],
    };
  },
  watch:{
    catId(newName, oldName) {
      this.loadList();
    }
  },
  created() {
    this.loadList();
  },
  methods: {
    async loadList() {
      let data = {
        id: this.goodsId,
        cat_id: this.catId,
      };
      const res = await goodsList(data);
      if (res.statusCode == 200) {
        this.recommendGoodsData = res.data.data;
      }
    },
    replacePage(goodsId) {
      switch (this.target) {
        case "router":
          this.$router.push({ path: "goods/"+goodsId });
          break;
        case "replace":
          this.$emit("refresh-goods", goodsId);
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Cup-boxs{
  width: 1200px;
}
.Cup-box{
  width: 1210px;
  display: flex;
  flex-wrap: wrap;
}
.cup {
  display: inline-block;
  width: 232px;
  /* 为了保持一致增加了7个px */
  height: 343px;
  /* 到时候要修改背景颜色 */
  background: #fff;
  margin-right: 10px;
  margin-bottom: 10px;
  .cup-img img {
    width: 232px;
    height: 232px;
    cursor: pointer;
  }
  .cup-font {
    margin-left: 16px;
  }
  .cup-introduce {
    width: 191px;
    height: 41px;
    font-size: 14px;
    line-height: 24px;
    margin: 14px 0 15px 0;
    p{
      cursor: pointer;
    }
  }
  .cup-money {
    display: flex;
    line-height: 20px;
    .cup-integral {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-right: 18px;
      font-size: 18px;
      strong {
        color: #e4007f;
      }
      & > strong:last-of-type {
        display: block;
        font-size: 14px;
        position: relative;
        top: -2px;
      }
      .cup-number {
        display: block;
        position: relative;
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .cup-price {
      span {
        font-size: 14px;
        color: #707070;
        display: block;
        text-decoration: line-through;
      }
    }
  }
}
</style>
